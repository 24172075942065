
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Vendors
import moment from "moment";

// Models
import {AppCommonUser} from "@/models/app/common/User";
import {AppCommonDiagnosticCategory} from "@/models/app/common/diagnostic-category";

// Components
import {
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElSelect,
  ElOption,
  ElDatePicker,
} from "element-plus";

@Options({
  props: [
    "players",
    "diagnosticCategories",
  ],
  components: {
    ElRow,
    ElCol,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption,
    ElDatePicker,
  },
})
export default class AppCoachDiagnosticsRootFilterIndexVue extends Vue {
  players: Array<AppCommonUser> = [];
  diagnosticCategories: Array<AppCommonDiagnosticCategory> = [];

  formData: any = {
    filter: {
      user_id: "*",
      diagnostic_category_id: "*",
      date: [
        moment(),
        moment().add(14, "days"),
      ],
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };

  getDiagnosticsByFilter(): void {
    this.$emit("getDiagnosticsByFilter", this.formData);
  }
}
