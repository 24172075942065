
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppCommonUser} from "@/models/app/common/User";
import {AppCommonDiagnosticCategory} from "@/models/app/common/diagnostic-category";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachDiagnosticsNavigationVue from "@/components/coach/diagnostics/navigation.vue";
import AppCoachDiagnosticsRootFilterIndexVue from "@/views/app/coach/diagnostics/root/filter/index.vue";
import AppCoachDiagnosticsRootListIndexVue from "@/views/app/coach/diagnostics/root/list/index.vue";

// Services
import {getRequest, postRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachDiagnosticsNavigationVue,
    AppCoachDiagnosticsRootFilterIndexVue,
    AppCoachDiagnosticsRootListIndexVue,
  },
})
export default class AppCoachDiagnosticsRootIndexVue extends Vue {
  isLoading: boolean | null = false;

  players: Array<AppCommonUser> = [];
  diagnosticCategories: Array<AppCommonDiagnosticCategory> = [];

  $refs!: {
    AppCoachDiagnosticsRootListIndexVue: any;
  };

  getDiagnosticsByFilter(formData: any): void {
    this.$refs.AppCoachDiagnosticsRootListIndexVue.getDiagnostics(formData);
  }

  async getPlayers(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/utility/organization-members`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        role: "player",
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.players = r.data;
      }
    });
    this.isLoading = false;
  }

  async getDiagnosticCategories(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/utility/diagnostic-categories`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.diagnosticCategories = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getPlayers();
    await this.getDiagnosticCategories();
  }
}
